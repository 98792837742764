import { FC } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export type DailyStats = {date: string, value: number};

export const StatsCard: FC<{ title: string; data?: DailyStats[]; stats?: { title: string; value: string }[]; }> = (props) => {
  return (
    <div className="flex flex-1 flex-col h-72 bg-gray-200 rounded-2xl text-black p-4">
      <h2 className="font-bold text-2xl">{props.title}</h2>
      <div className="flex flex-1">
        <div className="flex flex-col gap-2 w-1/4 my-auto">
          {props.stats?.map((item) => (
            <div key={item.title}>
              <h3>{item.title}</h3>
              <p className="text-xl font-bold px-2">{item.value}</p>
            </div>
          ))}
        </div>
        <div className="flex-1">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={props.data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
