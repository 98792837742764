import { FC, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { TELENOR_API_URL } from "../../../../env";

export const AttachmentsButton: FC<{ files?: { name: string; hash: string }[] }> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="flex">
      <button className="ml-auto" onClick={handleClick}>
        <img className="h-8 w-8" src="/assets/images/flows/attachment.svg" />
      </button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {props.files?.map((file) => (
          <MenuItem onClick={handleClose}>
            <a href={`${TELENOR_API_URL}/flows/orders/files/${file.hash}`}>{file.name}</a>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
