import { FlowStepModel, FlowOrderDataModel, FlowOrderDataCreator, FlowModel, FlowStepCreator, FlowStatusModel, ExecutionState } from ".";

type FlowOrderExecutionState = "" | "completed" | "awaiting" | "processing" | "operator";

export interface FlowOrderModel {
  id: string;
  flow_id: string;
  group_id: string;
  state: FlowOrderExecutionState;
  status: string;
  status_group: string;
  data: FlowOrderDataModel;
  steps: FlowStepModel[];
  queue_next?: boolean;
  files?: { name: string; hash: string }[];
}

export function FlowOrderCreator(): {
  empty: (flow?: FlowModel) => FlowOrderModel;
  fromBackendArray: (backendArray: any[]) => FlowOrderModel[];
  fromBackendObject: (backendObject: any) => FlowOrderModel;
  toBackendObject: (order: FlowOrderModel) => any;
} {
  function empty(flow?: FlowModel): FlowOrderModel {
    return {
      id: "",
      flow_id: flow?.flow_id || "",
      group_id: flow?.group_id || "",
      state: "" as FlowOrderExecutionState,
      status: "",
      status_group: "",
      data: [],
      steps: flow?.steps || [],
    };
  }

  function fromBackendArray(bo: any): FlowOrderModel[] {
    return bo.map((item: any) => ({ id: item.id, status: item.meta?.status?.value, status_group: item.meta?.status?.group, group: item.meta.group }));
  }

  function fromBackendObject(bo: any): FlowOrderModel {
    return {
      id: bo.id,
      flow_id: bo.flow_id,
      group_id: bo.group_id,
      state: bo.flow_state,
      status: bo.status,
      status_group: "",
      data: FlowOrderDataCreator().fromBackendObject(bo.raw_data, bo.steps as FlowStepModel[]),
      steps: bo.steps.map((item: any) => FlowStepCreator().fromBackendObject(item)),
      queue_next: true,
      files: bo.files,
    };
  }

  function toBackendObject(order: FlowOrderModel) {
    return {
      id: order.id,
      flow_id: order.flow_id,
      state: order.state,
      status: order.status,
      raw_data: order.data.map((item) => {
        const mapped: any = { ...item };
        delete mapped.model;
        return mapped;
      }),
      queue_next: order.queue_next,
      files: order.files,
    };
  }
  return { empty, fromBackendArray, fromBackendObject, toBackendObject };
}
