import { useEffect, useState } from "react";
import { ENV, TELENOR_PORTAL_SERVICES_URL } from "../../env";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import { OrderExportModel } from "../../models/hw/OrderExportModel";

const apiUrl = TELENOR_PORTAL_SERVICES_URL;
// const apiUrl = "http://localhost:8000";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
    paddingHorizontal: 32,
    paddingVertical: 16,
  },
  section: {
    padding: 8,
  },
  lineLabel: {
    width: 128,
    fontSize: 10,
    fontWeight: "extrabold",
  },
  lineValue: {
    fontSize: 10,
  },
  h1: {
    fontSize: 16,
    fontWeight: "bold",
    paddingVertical: 8,
  },
  h2: {
    fontSize: 12,
    fontWeight: "bold",
    paddingVertical: 4,
  },
});

const PdfLine = (props: { label?: string; value?: string }) => {
  return props.label || props.value ? (
    <View style={{ display: "flex", flexDirection: "row", paddingHorizontal: 8, paddingVertical: 2 }}>
      {props.label ? <Text style={styles.lineLabel}>{props.label}:</Text> : null}
      {props.value ? <Text style={styles.lineValue}>{props.value}</Text> : null}
    </View>
  ) : null;
};

const PdfTableLine = (props: { cells: string[] }) => {
  return (
    <View style={{ display: "flex", flexDirection: "row", paddingHorizontal: 8, paddingVertical: 2 }}>
      {props.cells.map((cell) => (
        <Text style={{ ...styles.lineValue, flex: "1 1 0%" }}>{cell}</Text>
      ))}
    </View>
  );
};

export const HwOrderExport = (props: { hash: string }) => {
  const [order, setOrder] = useState<OrderExportModel>();
  const [error, setError] = useState<string>("");

  console.log("ORDER", order);

  useEffect(() => {
    (async () => {
      try {
        const url = `${apiUrl}/hw/orders/hash/${props.hash}?env=${ENV}`;
        const res = await fetch(url);
        if (res.ok) {
          const orderData = await res.json();
          setOrder(orderData as OrderExportModel);
        } else {
          setError(await res.text());
        }
      } catch (err: any) {
        setError(err.toString());
      }
    })();
  }, []);

  return order ? (
    <div className="flex h-screen">
      <PDFViewer className="flex flex-1">
        <Document>
          <Page size="A4" style={styles.page}>
            <View>
              <Image style={{ width: "120", height: "51" }} src="/assets/images/logo-telenor.png" />
            </View>
            <View>
              <Text style={styles.h1}>Ordrebekreftelse</Text>
            </View>
            <View>
              <Text style={styles.h2}>Oppdragsgiver</Text>
              <PdfLine value={order.customer.name1} />
              <PdfLine value={order.customer.name2} />
              <PdfLine value={order.customer.address1} />
              <PdfLine value={order.customer.address2} />
              <PdfLine value={`${order.customer.zipcode} ${order.customer.city}`} />
            </View>
            <View>
              <Text style={styles.h2}>Varemottaker</Text>
              <PdfLine value={order.delivery.name1} />
              <PdfLine value={order.delivery.name2} />
              <PdfLine value={order.delivery.address1} />
              <PdfLine value={order.delivery.address2} />
              <PdfLine value={`${order.delivery.zipcode} ${order.delivery.city}`} />
            </View>
            <View>
              <Text style={styles.h2}>Informasjon</Text>
              <PdfLine label="Dokumentnummer" value={order.information.documentNumber} />
              <PdfLine label="Dokumentdato" value={order.information.documentDate} />
              <PdfLine label="Valuta" value={order.information.currency} />
              <PdfLine label="Leveringsdato" value={order.information.deliveryDate} />
              <PdfLine label="Ansvarlig selger" value={order.information.sellerName} />
              <PdfLine label="Deres referanse" value={order.information.reference} />
              <PdfLine label="Filial" value={order.information.branch} />
              <PdfLine label="Bestillingsnummer" value={order.information.casenr} />
              <PdfLine label="Portal ordre id" value={order.information.orderId} />
            </View>
            <View>
              <Text style={styles.h2}>Merknad</Text>
              <PdfLine label="Kundereferanse" value={order.notice.reference} />
              <PdfLine label="Kunderekvisisjon" value={order.notice.requisition} />
            </View>
            <View>
              <Text style={styles.h2}>Artikler</Text>
              <PdfTableLine cells={["Artikkel", "Varetekst", "Antall", "Pris", "Beløp"]} />
              {order.payment.articles.map((article) => (
                <PdfTableLine cells={[article.number, article.description, article.quantity, article.outprice, article.price.toString()]} />
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  ) : (
    <div className="text-black">{error || "Loading..."}</div>
  );
};
