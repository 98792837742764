import { FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

function SettingsField({ model, value, onSetValue }: { model: FlowStepSettingModel; value: any; onSetValue: (value: any) => void }) {
  if (model.type === "string") {
    return (
      <Tooltip title={model.description}>
        <TextField label={model.name || model.id} size="small" value={value} onChange={(e) => onSetValue(e.target.value)} />
      </Tooltip>
    );
  } else if (model.type === "text") {
    return (
      <Tooltip title={model.description}>
        <TextField label={model.name || model.id} size="small" multiline minRows={4} maxRows={32} value={value} onChange={(e) => onSetValue(e.target.value)} />
      </Tooltip>
    );
  } else if (model.type === "number") {
    return (
      <Tooltip title={model.description}>
        <TextField label={model.name || model.id} type="number" size="small" value={value} onChange={(e) => onSetValue(parseFloat(e.target.value))} />
      </Tooltip>
    );
  } else if (model.type === "boolean") {
    return (
      <Tooltip title={model.description}>
        <FormControlLabel control={<Switch checked={value as boolean} onChange={(e) => onSetValue(e.target.checked)} />} label={model.name || model.id} />
      </Tooltip>
    );
  } else if (model.type === "date") {
    return (
      <Tooltip title={model.description}>
        <div className="flex flex-1">
          <DatePicker className="flex flex-1" timezone="UTC" label={model.name || model.id} value={value} onChange={(newValue) => onSetValue(newValue)} />
        </div>
      </Tooltip>
    );
  } else if (model.type === "raw data") {
    return (
      <Tooltip title={model.description}>
        <TextField label={model.name || model.id} size="small" multiline minRows="5" value={value} onChange={(e) => onSetValue(e.target.value)} />
      </Tooltip>
    );
  } else if (model.type === "select") {
    return (
      <Tooltip title={model.description}>
        <FormControl>
          <InputLabel id={model.id}>{model.name || model.id}</InputLabel>
          <Select labelId={model.id} label={model.name || model.id} value={value} onChange={(e) => onSetValue(e.target.value)}>
            {model.values?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    );
  }
  return <></>;
}

export type FlowStepSettingModel = {
  id: string;
  name?: string;
  type?: string;
  description?: string;
  values?: { id: string; name: string }[];
  value: string;
};

export function FlowStepSettings({ settings, onSetSettings }: { settings?: FlowStepSettingModel[]; onSetSettings: (settings: FlowStepSettingModel[]) => void }) {
  return (
    <div className="flex flex-col w-2/3 mx-auto gap-2">
      {settings?.map(
        (field) => (
          <SettingsField key={field.id} model={field} value={field.value} onSetValue={(value) => onSetSettings(settings.map((item) => (item === field ? { ...item, value: value } : item)))} />
        )
        // setting.type === "select" ? (
        //   <Tooltip title={setting.description}>
        //     <FormControl>
        //       <InputLabel>{setting.name || setting.id}</InputLabel>
        //       <Select label={setting.name || setting.id} size="small" value={setting.value} onChange={(e) => onSetSettings(settings.map((i) => (i === setting ? { ...i, value: e.target.value } : i)))}>
        //         {setting.values?.map((v) => (
        //           <MenuItem key={v.id} value={v.id}>
        //             {v.name}
        //           </MenuItem>
        //         ))}
        //       </Select>
        //     </FormControl>
        //   </Tooltip>
        // ) : (
        //   <Tooltip title={setting.description}>
        //     <TextField label={setting.name || setting.id} size="small" value={setting.value} onChange={(e) => onSetSettings(settings.map((i) => (i === setting ? { ...i, value: e.target.value } : i)))} />
        //   </Tooltip>
        // )
      )}
    </div>
  );
}
