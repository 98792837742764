import { FlowNodeTypeModel, FlowStepFieldModel, FlowStepFieldsModel, FlowStepModel } from "../../../models";

function isFieldChanged(stepField: FlowStepFieldModel, defField: FlowStepFieldModel) {
  let changed = false;
  changed = changed || stepField.description !== defField.description;
  changed = changed || stepField.id !== defField.description;
  changed = changed || stepField.name !== defField.description;
  changed = changed || stepField.type !== defField.description;
  changed = changed || stepField.description !== defField.description;
  changed = changed || stepField.description !== defField.description;
  changed = changed || stepField.description !== defField.description;
  return changed;
}

function getStepUpdate(stepFields: FlowStepFieldsModel, definition?: FlowNodeTypeModel): FlowStepFieldsModel | null {
  return definition
    ? {
        in: definition.in,
        out: definition.out,
      }
    : null;
  // const update = {};
  // if (definition) {
  //   for (const defInField of definition.in) {
  //     const stepInField = stepFields.in.find(item => item.id === defInField.id);
  //     if(stepInField) {
  //       // step
  //     } else {
  //       // update[defInField.]
  //     }
  //   }
}

export function FlowStepFieldsUpdateButton({ step, definition, onClick }: { step: FlowStepModel; definition?: FlowNodeTypeModel; onClick: (step: FlowStepModel) => void }) {
  console.log("STEP", step);
  console.log("DEFINITION", definition);

  const update = getStepUpdate(step.step_data, definition /*? { ...definition, in: [...definition.in, { id: "test", description: "test", name: "test", type: "string" }], out: [] } : undefined*/);
  return update ? (
    <div className="flex flex-col gap-2 text-red-600">
      <button className="ml-auto p-1 rounded bg-gray-400" onClick={() => onClick({ ...step, step_data: { in: update.in, out: update.out } })}>
        {/* Trinndefinisjonen er oppdatert. Klikk for å laste den nye definisjonen. */}
        <img className="h-8 w-8" src="/assets/images/refresh.svg" />
      </button>
    </div>
  ) : null;
}
