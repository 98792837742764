import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { FlowStepModel, FlowStepNextStatusModel, FlowStepNextStatusCreator } from "../../../models";

type StatusAction = "select" | "add" | "delete" | "edit";

function NextSteps({ step, activeStatus, onStatusAction }: { step: FlowStepModel; activeStatus?: FlowStepNextStatusModel; onStatusAction: (action: StatusAction, status: FlowStepNextStatusModel, updatedStatus?: FlowStepNextStatusModel) => void }) {
  const [newStatus, setNewStatus] = useState(FlowStepNextStatusCreator().empty());

  return (
    <div className="flex flex-1">
      <Box component="fieldset" sx={{ borderWidth: "2px", width: "100%" }}>
        <legend>Next Statuses</legend>
        <div className="flex flex-col m-2 gap-2">
          <ul className="flex flex-col gap-2">
            {step.next_statuses
              // ?.map((statusItem) => ({...statusItem, status: flow.steps?.find((stepItem) => stepItem.status === statusItem.status)}))
              ?.map((statusItem) => (
                <li key={statusItem.name} className={`flex flex-col p-2 rounded-md border-2 gap-2 ${activeStatus === statusItem ? "bg-gray-400" : ""}`}>
                  <button className={`flex text-left`} onClick={() => onStatusAction("select", statusItem)}>
                    <div>
                      <h3>{statusItem.name}</h3>
                      <p className="text-xs">{statusItem.description}</p>
                    </div>
                    {!step.automated ? (
                      <button
                        className="ml-auto my-auto h-6 w-6"
                        onClick={(e) => {
                          e.stopPropagation();
                          onStatusAction("delete", statusItem);
                        }}
                      >
                        <img src="/assets/images/flows/remove-black.svg" alt="remove" />
                      </button>
                    ) : null}
                  </button>
                  {/* <Select className="bg-white w-full" size="small" value={statusItem.status} onChange={(e) => onSetNextStatuses(step.next_statuses.map((item) => (item === statusItem ? { ...statusItem, status: e.target.value } : item)))}>
                      {nextSteps?.map((step) => (
                        <MenuItem key={step.status} value={step.status}>
                          <div className="">
                            <h3>{step.name}</h3>
                            <p className="text-xs">({step.status})</p>
                          </div>
                        </MenuItem>
                      ))}
                    </Select> */}
                </li>
              ))}
          </ul>
          {!step.automated ? (
            <div className="flex m-2 gap-2">
              <div className="flex flex-col flex-1 gap-1">
                <TextField label="Name" size="small" value={newStatus.name} onChange={(e) => setNewStatus({ ...newStatus, name: e.target.value })} />
                <TextField label="Description" size="small" value={newStatus.description} onChange={(e) => setNewStatus({ ...newStatus, description: e.target.value })} />
              </div>
              <button
                className="my-auto h-6 w-6 mr-1"
                onClick={() => {
                  onStatusAction("add", newStatus);
                  onStatusAction("select", newStatus);
                  setNewStatus(FlowStepNextStatusCreator().empty());
                }}
              >
                <img src="/assets/images/flows/add-black.svg" alt="add" />
              </button>
            </div>
          ) : null}
        </div>
      </Box>
    </div>
  );
}

function InFieldDataSource({ dataSources, dataSource, setDataSource }: { dataSources?: FlowStepModel[]; dataSource?: string; setDataSource: (dataSourceString: string) => void }) {
  // const [selectedStep, setSelectedStep] = useState<FlowStepModel>();
  // const [selectedField, setSelectedField] = useState<string>("");

  const [stepStatus, fieldId] = dataSource?.split(".") || [];
  const selectedStep = dataSources?.find((step) => step.status === stepStatus);

  return dataSources?.length ? (
    <div className={`grid grid-cols-4 w-full gap-1`}>
      <div className="my-auto">Get Data From:</div>
      <FormControl>
        <InputLabel>Status</InputLabel>
        <Select label="Status" size="small" value={selectedStep?.status} onChange={(e) => setDataSource(e.target.value)}>
          {dataSources?.map((step) => (
            <MenuItem key={step.status} value={step.status}>
              {step.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedStep ? (
        <FormControl>
          <InputLabel>Field</InputLabel>
          <Select label="Status" size="small" value={fieldId} onChange={(e) => setDataSource(`${stepStatus}.${e.target.value}`)}>
            {selectedStep?.step_data.out?.map((stepOutField) => (
              <MenuItem key={stepOutField.id} value={stepOutField.id}>
                {stepOutField.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
      {stepStatus && fieldId ? (
        <div className="my-auto text-xs">
          {stepStatus}.{fieldId}
        </div>
      ) : null}
    </div>
  ) : (
    <></>
  );
}

function FieldMapping({ steps, from, to, onSetFrom }: { steps: FlowStepModel[]; from: string; to: string; onSetFrom: (from: string) => void }) {
  const [fromStatus, fromField] = from.split(".");

  const fromStatusStep = steps.find((step) => step.status === fromStatus);

  return (
    <div className="grid grid-cols-3 m-1">
      <div>
        <FormControl>
          <InputLabel>Status</InputLabel>
          <Select label="Status" size="small" value={fromStatus || ""} onChange={(e) => onSetFrom(`${e.target.value}`)}>
            {steps?.map((step) => (
              <MenuItem key={step.status} value={step.status}>
                {step.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex">
        {fromStatus ? (
          <FormControl>
            <InputLabel>Field</InputLabel>
            <Select label="Field" size="small" value={fromField || ""} onChange={(e) => onSetFrom(`${fromStatus}.${e.target.value}`)}>
              {fromStatusStep?.step_data?.out.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </div>
      <div>{to}</div>
    </div>
  );
}

function MappingPanel({ status, fromSteps, nextSteps, onSetStatus }: { status: FlowStepNextStatusModel; fromSteps: FlowStepModel[]; nextSteps: FlowStepModel[]; onSetStatus: (status: FlowStepNextStatusModel) => void }) {
  //   const [selectedNextStep, setSelectedNextStep] = useState<FlowStepModel>();

  const selectedNextStep = nextSteps.find((item) => item.status === status.status);

  function automap() {
    const map: {from: string, to: string}[] = selectedNextStep?.step_data.in.map(toField => {
      const to = toField.id;
      const fromField = fromSteps.reverse().flatMap(step => step.step_data.out.map(outField => ({step: step.status, out: outField.id}))).find(item => item.out.includes(to));
      return {from: fromField ? `${fromField.step}.${fromField.out}` : "", to};
    }) || [];
    onSetStatus({ ...status, mapping: map });
  }

  return status ? (
    <div className="flex flex-col flex-1 p-2 gap-2">
      <FormControl>
        <InputLabel>Next Status</InputLabel>
        <Select
          label="Next Status"
          size="small"
          value={selectedNextStep?.status || ""}
          onChange={(e) => {
            const selectedStep = nextSteps.find((item) => item.status === e.target.value);
            if (selectedStep && selectedStep.status !== status.status) {
              onSetStatus({ ...status, status: selectedStep.status, mapping: [] });
            }
          }}
        >
          {nextSteps?.map((item) => (
            <MenuItem key={item.status} value={item.status}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="flex">
        <button className="ml-auto px-2 py-1 rounded border-2 border-black" onClick={automap}>Automatch</button>
      </div>
      <div className="grid grid-cols-3">
        <h3 className="col-span-2">From:</h3>
        <h3>To:</h3>
      </div>
      {selectedNextStep
        ? selectedNextStep.step_data?.in?.map((item) => (
            <FieldMapping
              key={item.id}
              steps={fromSteps}
              from={status?.mapping?.find((i) => i.to === item.id)?.from || ""}
              to={`${selectedNextStep.name} -> ${item.name}`}
              onSetFrom={(from) => {
                const foundMapping = status?.mapping?.find((i) => i.to === item.id);
                if (!foundMapping) {
                  const newMapping = { from: from, to: item.id };
                  onSetStatus({ ...status, mapping: [...status.mapping, newMapping] });
                } else {
                  onSetStatus({ ...status, mapping: status.mapping.map((item) => (item === foundMapping ? { ...item, from: from } : item)) });
                }
              }}
            />
          ))
        : null}
    </div>
  ) : null;
}

export function FlowStepOutputMapping({ steps, step, onSetNextStatuses }: { steps: FlowStepModel[]; step: FlowStepModel; onSetNextStatuses: (statuses: FlowStepNextStatusModel[]) => void }) {
  const [activeStatus, setActiveStatus] = useState<FlowStepNextStatusModel>();

  function statusActionHandler(action: StatusAction, status: FlowStepNextStatusModel, updatedStatus?: FlowStepNextStatusModel) {
    if (action === "select") {
      setActiveStatus(status);
    } else if (action === "add") {
      onSetNextStatuses([...step.next_statuses, status]);
      setActiveStatus(status);
    } else if (action === "delete") {
      onSetNextStatuses(step.next_statuses.filter((item) => item !== status));
      setActiveStatus(undefined);
    } else if (action === "edit" && updatedStatus) {
      onSetNextStatuses(step.next_statuses.map((item) => (item.name === status.status ? updatedStatus : item)));
      setActiveStatus(updatedStatus);
    }
  }

  const previousSteps = steps.slice(0, steps.indexOf(step) + 1);
  const nextSteps = steps.slice(steps.indexOf(step) + 1);

  return (
    <div className="flex flex-1">
      <div className="flex w-1/4">
        <NextSteps step={step} activeStatus={activeStatus} onStatusAction={statusActionHandler} />
      </div>
      <div className="flex flex-col flex-1">
        {step.next_statuses.map((nextStatus) => (
          <div className={`flex flex-1 ${nextStatus !== activeStatus ? "hidden" : ""}`}>
            <MappingPanel
              key={`${nextStatus.status}`}
              status={nextStatus}
              fromSteps={previousSteps}
              nextSteps={nextSteps}
              onSetStatus={(status) => {
                onSetNextStatuses(step.next_statuses.map((item) => (item === nextStatus ? status : item)));
                setActiveStatus(status);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
