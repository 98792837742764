import { useContext, useEffect, useState } from "react";
import { ModuleContext } from "../../../common/components";
import { FlowBackend } from "../../backends";
import { ExecutionState, FlowOrderDataModel, FlowOrderExecutionStepCreator, FlowOrderExecutionStepModel, FlowOrderModel, OrderAction } from "../../models";
import { OrderStepEditor } from "./OrderStepEditor";
import { OrderSteps } from "./OrderSteps";
import { OrderDataJsonViewer } from "./OrderDataJsonViewer";
import { usePermissionChecker } from "../../../common/hooks/usePermissionChecker";
import { FormControlLabel, Switch } from "@mui/material";
import { AttachmentsButton } from "./AttachmentsButton";
// import { FormControlLabel, Switch } from "@mui/material";

type ViewType = "data" | "json";
const views: { name: string; viewType: ViewType }[] = [
  { name: "Data", viewType: "data" },
  { name: "Json", viewType: "json" },
];

function OrderDetailsLayout({ activeOrder, activeStep, onAction }: { activeOrder: FlowOrderModel; activeStep?: FlowOrderExecutionStepModel; onAction: (action: OrderAction, actionData?: FlowOrderExecutionStepModel | string) => void }) {
  const [edit, setEdit] = useState<boolean>(true);
  const permissionChecker = usePermissionChecker();

  const [view, setView] = useState<ViewType>("data");

  useEffect(() => {
    setEdit(false);
  }, [activeStep]);

  return (
    <div className="flex flex-1 flex-col">
      {activeOrder.files?.length ? <AttachmentsButton files={activeOrder.files}/> : null}
      <div className="flex flex-1 py-4 gap-4">
        {activeOrder.steps ? (
          <div className="flex flex-col w-1/6">
            <OrderSteps steps={activeOrder.data} activeStatus={activeStep?.status || ""} onSetActiveStep={(step) => onAction("select-status", step?.status)} />
            {/* <button className="mt-auto w-full rounded-md border-2 p-2" onClick={() => onAction("save-order")}>
              Save
            </button> */}
            {/* <div className="flex">
              <button className="" onClick={() => onAction("save-order")}>
                <img className="h-10 w-10" src="/assets/images/flows/save.svg" alt="save" />
              </button>
            </div> */}
          </div>
        ) : null}
        {activeStep ? (
          <div className="flex flex-1 flex-col bg-gray-400 rounded-md p-4 border-2 gap-2 ">
            <div className="flex items-center">
              {/* {activeStep.state === "completed" ? <button className="tp-flex-button-cancel">Tilbakestill saken til dette punktet</button> : null} */}

              <ul className="flex ml-auto gap-4">
                {views.map((item) => (
                  <li key={item.name}>
                    <button className={view === item.viewType ? "text-black" : ""} onClick={() => setView(item.viewType)}>
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-1 flex-col text-black bg-white p-4 rounded-md">
              {view === "data" ? <OrderStepEditor key={activeStep.status} stepData={activeStep} onAction={onAction} editingOn={permissionChecker.isAccessAllowed(2, "flows")} /> : null}
              {view === "json" ? <OrderDataJsonViewer orderData={activeOrder.data} /> : null}
            </div>
            {activeStep.next_status ? (
              edit ? (
                <div className="flex gap-2 items-center h-14">
                  <label className="text-black">Endre dataene permanent og tilbakestill saken til dette punktet?</label>
                  <button className="tp-flex-button-cancel" onClick={() => onAction?.("reset-status", activeStep.status)}>
                    Ja
                  </button>
                  <button className="tp-flex-button-confirm" onClick={() => setEdit(false)}>
                    Nei
                  </button>
                </div>
              ) : (
                <div className="flex">
                  <button title="Tilbakestill saken til dette punktet">
                    <img className="h-14" src="/assets/images/flows/reset-step.svg" onClick={() => setEdit(true)} />
                  </button>
                  {/* <RunStepButton name="Reset" description="Tilbakestil till dette punktet" onClick={() => setEdit(true)} /> */}
                  {/* // <FormControlLabel className="flex ml-auto" control={<Switch checked={edit} onChange={(e) => setEdit(e.currentTarget.checked)} />} label="Reset" /> */}
                </div>
              )
            ) : (
              <div className="flex">
                <button title="Lagre saken">
                  <img className="h-14" src="/assets/images/flows/save-black.svg" onClick={() => onAction("save-order")} />
                </button>
                <div className="flex ml-auto gap-2">
                  {activeStep.model?.next_statuses.map((statusItem) => (
                    <RunStepButton key={statusItem.status} name={statusItem.name} disabled={!!activeStep.next_status} description={statusItem.description} onClick={() => onAction?.("next-status", statusItem.status)} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

function RunStepButton({ name, description, disabled, onClick }: { name: string; description: string; disabled?: boolean; onClick: () => void }) {
  const [over, setOver] = useState<boolean>(false);

  return (
    <button disabled={disabled} className={`flex h-14 rounded-md border-2 ${disabled ? "" : over ? "border-black" : "border-black"}`} onClick={onClick} onMouseEnter={() => setOver(true)} onMouseLeave={() => setOver(false)}>
      <div className="relative flex flex-1 h-full">
        {!disabled && over ? (
          <div className="absolute w-full h-full flex z-10">
            <img className="mr-auto my-auto select-none h-8 w-8 mx-2" src="/assets/images/flows/play.svg" alt="play" />
          </div>
        ) : null}
        <div className={`my-auto text-left text-black pr-2 ${over ? "ml-14 mr-2" : "ml-2 mr-14"} ${disabled ? "text-gray-400" : ""}`}>
          <h3 className="">{name}</h3>
          <p className="text-xs">{description}</p>
        </div>
      </div>
    </button>
  );
}

function initializeOrder(order: FlowOrderModel) {
  const isNewOrder = order.data.length === 0;
  if (isNewOrder) {
    const orderData: FlowOrderDataModel = [];
    order.steps.filter((step) => step.first_step).forEach((step) => orderData.push(FlowOrderExecutionStepCreator().empty(step)));
    return {
      ...order,
      data: orderData,
    };
  }
  return order;
}

export function OrderDetails({ order, update, onUpdateOrder }: { order: FlowOrderModel; update?: boolean; onUpdateOrder: (updatedOrder: FlowOrderModel, close?: boolean) => void }) {
  const processor = useContext(ModuleContext);

  const [activeOrder, setActiveOrder] = useState<FlowOrderModel>(initializeOrder(order));
  const [activeStep, setActiveStep] = useState<FlowOrderExecutionStepModel | undefined>(order.data?.length ? order.data[order.data.length - 1] : undefined);
  // const [orderUpdated, setOrder]

  useEffect(() => {
    const timer = setInterval(() => {
      if (update) {
        processor?.runSilently(FlowBackend.getOrder, order.id).then((result) => {
          if (result.state === "completed" || result.state === "operator") {
            clearTimeout(timer);
          }
          setActiveOrder(result);
        });
      }
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, [update]);

  async function runOrder(stop: boolean) {
    if (activeStep) {
      const activeStepIndex = activeOrder.data.findIndex((item) => item.status === activeStep.status);
      if (activeStepIndex >= 0) {
        const newStepData: FlowOrderExecutionStepModel = { ...activeStep, out: {}, state: "processing" };
        const newOrderData = activeOrder.data.slice(0, activeStepIndex);
        const newOrder = { ...activeOrder, data: [...newOrderData, newStepData] };
        setActiveOrder({ ...activeOrder, data: [...newOrderData, newStepData] });
        setActiveStep(newStepData);
        processor?.runSilently(FlowBackend.runOrderSteps, newOrder, activeStep.status, stop);
      }
    }
  }

  async function actionHandler(action: OrderAction, actionData?: FlowOrderExecutionStepModel | string) {
    if (action === "reset-status") {
      const selectedStatus = actionData as string;
      const selectedStepIndex = activeOrder.data.findIndex((item) => item.status === selectedStatus);
      if (selectedStepIndex >= 0) {
        const updatedOrder: FlowOrderModel = { ...activeOrder, data: activeOrder.data.slice(0, selectedStepIndex + 1) };
        updatedOrder.data[updatedOrder.data.length - 1].state = "operator";
        updatedOrder.data[updatedOrder.data.length - 1].next_status = "";
        updatedOrder.state = "operator";
        updatedOrder.queue_next = false;
        onUpdateOrder(updatedOrder);
        setActiveOrder(updatedOrder);
        setActiveStep(updatedOrder.data[updatedOrder.data.length - 1]);
      }
    } else if (action === "select-status") {
      const selectedStatus = actionData as string;
      let nextStepData = activeOrder.data.find((item) => item.status === selectedStatus);
      if (nextStepData) {
        setActiveStep(nextStepData);
      }
    } else if (action === "next-status") {
      const nextStatus = actionData as string;
      const nextStepModel = activeOrder.steps.find((item) => item.status === nextStatus);
      if (nextStepModel) {
        const nextStepData = FlowOrderExecutionStepCreator().empty(nextStepModel);
        const updatedOrder: FlowOrderModel = {
          ...activeOrder,
          queue_next: true,
          data: [...activeOrder.data.map((item) => (item.status === activeStep?.status ? { ...item, next_status: nextStatus, state: "completed" as ExecutionState } : item)), nextStepData],
        };
        onUpdateOrder(updatedOrder);
      }
      // const selectedStatus = actionData as string;
      // if (!selectedStatus) {
      //   setActiveStep(undefined);
      //   return;
      // }

      // const activeStepModel = activeOrder.steps.find((item) => item.status === activeStep?.status);
      // const nextStepModel = activeOrder.steps.find((item) => item.status === selectedStatus);
      // if (nextStepModel) {
      //   let updatedOrder: FlowOrderModel;
      //   let nextStepData = activeOrder.data.find((item) => item.status === selectedStatus);
      //   console.log("NESXT STEP DATA", nextStepData);
      //   if (!nextStepData) {
      //     nextStepData = FlowOrderExecutionStepCreator().empty(nextStepModel);
      //     if (activeStepModel) {
      //       for (const nextStatus of activeStepModel.next_statuses) {
      //         if (nextStatus.status === nextStepModel.status) {
      //           for (const field of nextStatus.mapping) {
      //             const [fromStep, fromField] = field.from.split(".");
      //             if (fromStep && fromField) {
      //               const foundFromStep = activeOrder.data.find((item) => item.status === fromStep);
      //               if (foundFromStep) {
      //                 nextStepData.in[field.to] = foundFromStep.out[fromField];
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }
      //     updatedOrder = { ...activeOrder, data: [...activeOrder.data.map((item) => (item === activeStep ? { ...activeStep, next_status: selectedStatus } : item)), nextStepData] };
      //   } else {
      //     updatedOrder = { ...activeOrder, data: activeOrder.data.map((item) => (item.status === nextStepData?.status ? nextStepData : item)) };
      //   }
      //   if (action === "next-status") {
      //     console.log("UPDATED ORDER", updatedOrder)
      //     onUpdateOrder({ ...updatedOrder, queue_next: true });
      //   } else {
      //     setActiveStep(nextStepData);
      //   }
      // }
    } else if (action === "set-data") {
      const newActiveStep = actionData as FlowOrderExecutionStepModel;
      if (activeStep && newActiveStep) {
        const activeStepIndex = activeOrder.data.indexOf(activeStep);
        const updated = { ...activeOrder, data: activeOrder.data.map((item) => (item.status === activeStep.status ? { ...newActiveStep } : item)) };
        // onUpdateOrder({ ...updated, queue_next: false });
        setActiveOrder(updated);
        setActiveStep(newActiveStep);
      }
    } else if (action === "run-step") {
      runOrder(true);
    } else if (action === "run-flow") {
      runOrder(false);
    } else if (action === "check-state") {
      processor?.runSilently(FlowBackend.getOrder, activeOrder.id).then((result) => setActiveOrder(result));
    } else if (action === "save-order") {
      const updatedOrder = { ...activeOrder };
      updatedOrder.state = "operator";
      updatedOrder.data[updatedOrder.data.length - 1].state = "operator";
      updatedOrder.queue_next = false;
      onUpdateOrder(updatedOrder, true);
    }
  }
  return <OrderDetailsLayout activeOrder={activeOrder} activeStep={activeStep} onAction={actionHandler} />;
}
