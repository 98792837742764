import { useContext, useEffect, useState } from "react";
import { CustomDataTable } from "../../../components/common/DataTable";
import { FlowModel, FlowOrderInfoModel } from "../models";
import AppContext from "../../../contexts/AppContext";
import { ClaimCell } from "./ClaimCell";
import { OrderTagDialog } from "./OrderTag";
import { ModuleContext } from "../../common/components";
import { FlowBackend } from "../backends";
import { OrderDetailsDialog } from "./OrderDetails";
import { SearchField } from "../../common/elements";

export type FlowOrderAction = "add" | "select" | "details" | "update" | "claim" | "unclaim" | "tag";

const INFO_TEXT_MAX_LENGTH = 50;

function ExpandedOrderInfo({ data }: { data: any }) {
  const order = data as FlowOrderInfoModel;

  return (
    <div className="flex gap-4 border-double border-b-8 px-16 py-4">
      <div className="flex flex-1 flex-col gap-2">
        {order.meta?.info?.map((item) => (
          <p>
            {item.title}: {item.value}
          </p>
        ))}
      </div>
      <div className="flex flex-1 gap-4">
        <div className="flex flex-1 flex-col gap-2">
          <p>Created: {order.dates.created || "-"}</p>
          <p>Updated: {order.dates.updated || "-"}</p>
          <p>Due: {order.dates.due || "-"}</p>
          <p>Completed: {order.dates.completed || "-"}</p>
        </div>
        {order.meta?.tags?.comment ? (
          <div className="flex flex-1 flex-col gap-2">
            <h3>Comments:</h3>
            <p className="px-2">{order.meta?.tags?.comment}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function ActiveOrderList({ flows }: { flows: FlowModel[] }) {
  const appContext = useContext(AppContext);
  const processor = useContext(ModuleContext);

  const [activeOrders, setActiveOrders] = useState<FlowOrderInfoModel[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<FlowOrderInfoModel[]>([]);
  const [dialog, setDialog] = useState<"" | "details" | "tag">("");
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedRow, setSelectedRow] = useState<FlowOrderInfoModel | null>(null);

  function filterOrders(orders: FlowOrderInfoModel[], searchText?: string) {
    if (searchText) {
      orders = orders.filter((order) => JSON.stringify(order).toLowerCase().includes(searchText?.toLowerCase()));
    }
    setFilteredOrders(orders);
  }

  async function loadActiveOrders() {
    const fetchedOrders = await processor?.run(FlowBackend.getActiveOrderInfos);
    setActiveOrders(fetchedOrders);
    setFilteredOrders(fetchedOrders);
  }

  async function claimOrder(orderId: string, unclaim?: boolean) {
    const claimed = unclaim ? await processor?.run(FlowBackend.unclaimOrder, orderId) : await processor?.run(FlowBackend.claimOrder, orderId);
    await loadActiveOrders();
  }

  async function tagOrder(orderId: string, tags: any) {
    await processor?.run(FlowBackend.tagOrder, orderId, tags);
    await loadActiveOrders();
  }

  const columns = [
    {
      name: " ",
      selector: (row: FlowOrderInfoModel) => row.meta?.tags?.color,
      cell: (row: FlowOrderInfoModel) => {
        const colorTag = row.meta?.tags?.color;
        const textTag = row.meta?.tags?.text;
        let color = colorTag ? `bg-${colorTag}-400` : "igray";
        return (
          <div className="w-12 mx-auto" title={textTag}>
            <button
              className={`flex w-8 h-8 mx-auto rounded-full ${color}`}
              onClick={(e) => {
                setSelectedRow(row);
                handleContextMenu(e);
                setDialog("tag");
              }}
            ></button>
          </div>
        );
      },
      sortable: true,
      width: "4rem",
    },
    {
      name: "ID",
      selector: (row: FlowOrderInfoModel) => parseInt(row.id),
      sortable: true,
      width: "6rem",
    },
    {
      name: "Flow",
      selector: (row: FlowOrderInfoModel) => flows.find((item) => item.flow_id === row.currentFlowId)?.name || "",
      sortable: true,
      width: "16rem",
    },
    {
      name: "State",
      selector: (row: FlowOrderInfoModel) => row.meta?.state?.value?.toUpperCase(),
      sortable: true,
      width: "16rem",
    },
    {
      name: "Claimed By",
      cell: (row: FlowOrderInfoModel) =>
        row.meta?.claimed ? (
          row.meta.claimed.id === appContext.login?.id ? (
            <ClaimCell initials={row.meta.claimed.initials} name={row.meta.claimed.name} action="unclaim" onClick={() => claimOrder(row.id, true)} />
          ) : (
            <ClaimCell initials={row.meta.claimed.initials} name={row.meta.claimed.name} />
          )
        ) : (
          <ClaimCell action="claim" onClick={() => claimOrder(row.id)} />
        ),
      sortable: true,
      width: "16rem",
    },
    {
      name: "CaseNr",
      selector: (row: FlowOrderInfoModel) => row.meta?.info?.find((item) => item.title === "Casenr")?.value || "",
      sortable: true,
      width: "16rem",
    },
    {
      name: "Created",
      selector: (row: FlowOrderInfoModel) => row.dates?.created || "",
      sortable: true,
      width: "8rem",
    },
    {
      name: "Comments",
      cell: (row: FlowOrderInfoModel) => {
        let comments = row.meta?.tags?.comment || "";
        if (comments.length > 50) {
          return <div title={comments}>{comments.slice(0, 50) + " ..."}</div>;
        }
        return <div title={comments}>{comments}</div>;
      },
      sortable: true,
    },
    // {
    //   name: "Info",
    //   selector: (row: FlowOrderInfoModel) => {
    //     const infoText = row.meta?.info?.map((item) => `${item.title}: ${item.value}`).join(", ");
    //     return infoText?.length > INFO_TEXT_MAX_LENGTH ? infoText.slice(0, INFO_TEXT_MAX_LENGTH - 3) + "..." : infoText;
    //   },
    //   sortable: true,
    // },
  ];

  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    (async () => {
      await loadActiveOrders();
    })();
  }, []);

  return (
    <div>
      <div className="flex flex-col flex-1 gap-2">
        <div className="flex flex-1 justify-between">
          <SearchField placeholder="Filtrer saker..." onSearch={(searchText) => filterOrders(activeOrders, searchText)} />
          {/* {filteredOrders.length > 0 && (
            <button onClick={() => setDialog("all-order-details")}>
              <img className="h-8 w-8" src="/assets/images/flows/edit-all.svg" alt="edit-all" />
            </button>
          )} */}
          <button onClick={() => loadActiveOrders()}>
            <img className="h-8 w-8" src="/assets/images/refresh.svg" alt="refresh" />
          </button>
        </div>
        <CustomDataTable
          columns={columns}
          data={filteredOrders}
          expandedRow={ExpandedOrderInfo}
          onMoreClicked={(order) => {
            setSelectedRow(order);
            setDialog("details");
          }}
          onRowDoubleClicked={(order) => {
            setSelectedRow(order);
            setDialog("details");
          }}
        />
        {selectedRow && dialog === "tag" ? (
          <OrderTagDialog
            position={contextMenuPosition}
            tags={selectedRow.meta.tags}
            onSetTags={(tags) => setSelectedRow({ ...selectedRow, meta: { ...selectedRow.meta, tags: tags } })}
            onSave={() => tagOrder(selectedRow.id, selectedRow.meta.tags)}
            onClose={() => setContextMenuPosition(null)}
          />
        ) : null}
        {selectedRow && dialog === "details" ? (
          <OrderDetailsDialog
            orderId={selectedRow.id}
            onClose={() => {
              setDialog("");
              loadActiveOrders();
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
