import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FlowStepFieldModel, FlowStepDataItemType, FlowStepModel, SupportedFieldTypes } from "../../../models";
import { useState } from "react";

export type FlowStepInterfaceFieldAction = "add" | "remove" | "switch" | "set";

export function FlowStepInterface({ locked, fields, setFields, dataSources }: { locked: boolean; fields: FlowStepFieldModel[]; setFields: (data: FlowStepFieldModel[]) => void; dataSources?: FlowStepModel[] }) {
  function dataItemActionHandler(dataItem: FlowStepFieldModel | null, action: FlowStepInterfaceFieldAction) {
    if (action === "add") {
      const newDataItem: FlowStepFieldModel = {
        id: `field${(fields?.length || 0) + 1}`,
        name: `Field ${(fields?.length || 0) + 1}`,
        description: "",
        type: "string",
      };
      setFields(fields?.length ? [...fields, newDataItem] : [newDataItem]);
    } else if (action === "remove") {
      setFields(fields?.filter((item) => item !== dataItem));
    } else if (action === "switch") {
      if (dataItem) {
        const i = fields.indexOf(dataItem);
        const updatedData = [...fields];
        updatedData[i] = updatedData[i + 1];
        updatedData[i + 1] = dataItem;
        setFields(updatedData);
      }
    } else if (action === "set") {
      if (dataItem) {
        const i = fields.indexOf(dataItem);
        const updatedData = [...fields];
        updatedData[i] = updatedData[i + 1];
        updatedData[i + 1] = dataItem;
        setFields(updatedData);
      }
    }
  }

  return (
    <div className={`flex flex-1 gap-2 ${locked ? "select-none" : ""}`}>
      <div className="flex flex-1 pl-2">
        <div className="flex flex-col gap-2 mx-auto">
          {fields?.map((field, i) => (
            <FlowStepInterfaceField
              key={i}
              locked={locked}
              field={field}
              setField={(updated) => setFields(fields.map((item) => (item === field ? updated : item)))}
              isLast={i === fields.length - 1}
              onAction={(action) => dataItemActionHandler(field, action)}
              dataSources={dataSources}
            />
          ))}
          {!locked ? (
            <button className="mx-auto h-6 w-6" onClick={() => dataItemActionHandler(null, "add")}>
              <img src="/assets/images/flows/add-black.svg" alt="add" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function FlowStepInterfaceField({
  locked,
  field,
  setField,
  isLast,
  onAction,
  dataSources,
}: {
  locked: boolean;
  field: FlowStepFieldModel;
  setField: (dataItem: FlowStepFieldModel) => void;
  isLast: boolean;
  onAction: (action: FlowStepInterfaceFieldAction) => void;
  dataSources?: FlowStepModel[];
}) {
  return (
    <>
      <div className="flex">
        <div className="flex flex-col gap-2">
          <div className={`grid grid-cols-4 w-full gap-1`}>
            <TextField disabled={locked} label="Id" size="small" value={field.id} onChange={(e) => setField({ ...field, id: e.target.value })} />
            <TextField disabled={locked} label="Name" size="small" value={field.name} onChange={(e) => setField({ ...field, name: e.target.value })} />
            <div className="flex gap-1">
              <FormControl className="flex flex-1" disabled={locked}>
                <InputLabel>Type</InputLabel>
                <Select label="Type" size="small" value={field.type} onChange={(e) => setField({ ...field, type: e.target.value as FlowStepDataItemType })}>
                  {SupportedFieldTypes?.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {field.type === "select" ? (
                <EditSelectFieldValuesButton
                  readonly={locked}
                  values={field.values}
                  onSave={(values) => {
                    setField({ ...field, values: values });
                  }}
                />
              ) : null}
            </div>
            <TextField disabled={locked} label="Description" size="small" value={field.description} onChange={(e) => setField({ ...field, description: e.target.value })} />
          </div>
        </div>
        {!locked ? (
          <button
            className="my-auto h-6 w-6"
            onClick={(e) => {
              onAction("remove");
              e.stopPropagation();
            }}
          >
            <img src="/assets/images/flows/remove-black.svg" alt="remove" />
          </button>
        ) : null}
      </div>
      {!locked && !isLast ? (
        <button className="mx-auto h-6 w-6">
          <img src="/assets/images/flows/switch-black.svg" onClick={() => onAction("switch")} alt="switch" />
        </button>
      ) : null}
    </>
  );
}

type SelectFieldValueModel = { id: number; name: string; value: string };

function EditSelectFieldValuesButton(props: { readonly?: boolean; values?: SelectFieldValueModel[]; onSave: (values: SelectFieldValueModel[]) => void }) {
  const [on, setOn] = useState<boolean>(false);
  const [values, setValues] = useState<string[]>(props.values?.map((item) => item.name) || []);
  const [newValue, setNewValue] = useState<string>("");

  return (
    <>
      <button className="flex w-10 bg-gray-400 rounded-md" onClick={() => setOn(true)}>
        <img className="m-auto" src="/assets/images/flows/list.svg" />
      </button>
      {on ? (
        <div className="flex modal fixed z-50 top-0 left-0 w-full h-full overflow-x-hidden bg-gray-200 bg-opacity-50 p-8" onClick={() => setOn(false)}>
          <div className="flex m-auto p-4" onClick={(e) => e.stopPropagation()}>
            <div className="flex flex-col m-auto bg-white text-black p-2 rounded-lg w-full h-full overflow-y-auto ">
              {props.readonly ? (
                <div className="flex flex-1 flex-col gap-2 p-2">
                  {values.map((fieldValue) => (
                    <p>{fieldValue}</p>
                  ))}
                </div>
              ) : (
                <div className="flex flex-1 flex-col gap-2 p-2">
                  {values?.map((fieldValue) => (
                    <div className="flex gap-2">
                      <TextField key={fieldValue} className="bg-white w-96" size="small" value={fieldValue} onChange={(e) => setValues(values.map((item) => (item === fieldValue ? e.target.value : item)))} />
                      <button onClick={() => setValues(values.filter((item) => item !== fieldValue))}>x</button>
                    </div>
                  ))}
                  <div className="flex gap-2">
                    <TextField
                      className="bg-white w-96"
                      label="New value"
                      size="small"
                      value={newValue}
                      onChange={(e) => setNewValue(e.target.value)}
                      onKeyUp={(e) => {
                        if ((e.code === "Enter" || e.code === "NumpadEnter") && newValue) {
                          setValues([...values, newValue]);
                          setNewValue("");
                        }
                      }}
                    />
                  </div>
                  <div className="flex">
                    <button
                      className="tp-button-confirm"
                      onClick={() => {
                        props.onSave(values.map((value, index) => ({ id: index, name: value, value: value })));
                        setOn(false);
                      }}
                    >
                      Lagre
                    </button>
                    <button
                      className="tp-button-cancel ml-auto"
                      onClick={() => {
                        setValues(props.values?.map((item) => item.name) || []);
                        setOn(false);
                      }}
                    >
                      Avbryt
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
