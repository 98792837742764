import { TELENOR_PORTAL_SERVICES_URL, ENV } from "../../../env";

const baseUrl = `${TELENOR_PORTAL_SERVICES_URL}`;

export async function getData(path: string): Promise<any> {
  // const res = await fetch(`${baseUrl}/${path}?env=${ENV}`, {
  const res = await fetch(`${baseUrl}/${path}`, {
    method: "GET",
  });
  const data = await res.json();
  return data;
}
